import {PubSub, elementInPosition, getScrollDirection} from "../util";
import sliders from "../sliders";
import ShapesParallax from "../ShapesParallax";
import inspireDraw from "../inspireDraw";
import inspireHover from "../inspireHover";

let inspireAnim;
const pubsub = new PubSub();

const about = {
  init: () => {
    let Sliders = Object.create(sliders);
    Sliders.addEvents();
    Sliders.init();
    Sliders.animate();

    new ShapesParallax(
      $(".vision-slider .shape-outline").add($(".vision-slide .box .shape-solid")).add($(".vision-slider-container .quote-svg")),
      $("#slider-shapes-ref"),
      100
    );

    inspireAnim = new inspireDraw();
  },
  finalize: () => {
    const $text = $(".module-text");
    const $inspire = $(".module-inspire");
    const $svg = $inspire.find("svg.inspire-text");
    const $history = $(".module-history");
    const $team = $(".module-team");
    const $community = $(".module-community");
    const $standards = $(".module-standards");

    $(document).on("ready scroll", () => {
      if (elementInPosition($text, 0.5)) {
         $text.find(".fade-text").removeClass("hidden")
      }
      if (elementInPosition($inspire, 0.33)) {
        setTimeout(() => {
          $inspire.find(".content-container").removeClass("hidden");
        }, 500);
      }

      if (elementInPosition($svg, 0.5)) {
        $svg.addClass("primary");
      }

      if (elementInPosition($history, 0.33)) {
        $history.removeClass("hidden");
        $history.find(".timeline .moment").each((i, elem) => {
          setTimeout(() => {
            $(elem).removeClass("hidden")
          }, i * 300);
        })
      }

      if (elementInPosition($team, 0.5)) {
        $team.find(".content-container").removeClass("hidden");

        const $members = $team.find(".member-container .member-info");
        $members.each((i, elem) => {
          setTimeout(() => {
            $(elem).removeClass("hidden");
          }, i * 200);
        });
      }

      if (elementInPosition($community, 0.5)) {
        $community.find(".content-container").removeClass("hidden");
        $community.find(".accordion-heading").each((i, elem) => {
          setTimeout(() => {
            $(elem).removeClass("hidden");
          }, (i + 1) * 200);
        });
      }

      if (elementInPosition($standards, 0.5)) {
        $standards.find(".content-container").removeClass("hidden");
        $standards.find(".standards-accordion .accordion-heading").each((i, elem) => {
          setTimeout(() => {
            $(elem).removeClass("hidden");
          }, (i + 1) * 200);
        });
      }
    });

    inspireHover.init();

    inspireAnim.addEventListener('incomplete', () => {
        pubsub.publish("complete", false);
    });

  },
}

export default about;
