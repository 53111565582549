import {rotate, PubSub} from "./util";

export default class inspireHover {

    static init() {
        const pubsub = new PubSub();
        pubsub.subscribe(this, "complete", (state) => {
            this.enabled = state;
        })

        $("svg .letter-i").hover(() => {
            if(this.enabled) {
                $("svg .letter-i").addClass("highlight");
                $(".text.innovation").addClass("highlight");
            }
        }, () => {
            $(".text.innovation").removeClass("highlight");
            $("svg .letter-i").removeClass("highlight");
        });

        $("svg .letter-n").hover(() => {
            if(this.enabled) {
                $("svg .letter-n").addClass("highlight");
                $(".text.nimble").addClass("highlight");
            }
        }, () => {
            $(".text.nimble").removeClass("highlight");
            $("svg .letter-n").removeClass("highlight");
        });

        $("svg .letter-s").hover(() => {
            if(this.enabled) {
                $("svg .letter-s").addClass("highlight");
                $(".text.sustainable").addClass("highlight");
            }
        }, () => {
            $(".text.sustainable").removeClass("highlight");
            $("svg .letter-s").removeClass("highlight");
        });

        $("svg .letter-p").hover(() => {
            if(this.enabled) {
                $("svg .letter-p").addClass("highlight");
                $(".text.passionate").addClass("highlight");
            }
        }, () => {
            $(".text.passionate").removeClass("highlight");
            $("svg .letter-p").removeClass("highlight");
        });

        $("svg .letter-i2").hover(() => {
            if(this.enabled) {
                $("svg .letter-i2").addClass("highlight");
                $(".text.integrity").addClass("highlight");
            }
        }, () => {
            $(".text.integrity").removeClass("highlight");
            $("svg .letter-i2").removeClass("highlight");
        });

        $("svg .letter-r").hover(() => {
            if(this.enabled) {
                $("svg .letter-r").addClass("highlight");
                $(".text.respect").addClass("highlight");
            }
        }, () => {
            $(".text.respect").removeClass("highlight");
            $("svg .letter-r").removeClass("highlight");
        });

        $("svg .letter-e").hover(() => {
            if(this.enabled) {
                $("svg .letter-e").addClass("highlight");
                $(".text.empowerment").addClass("highlight");
            }
        }, () => {
            $(".text.empowerment").removeClass("highlight");
            $("svg .letter-e").removeClass("highlight");
        });
    }
}

inspireHover.enabled = false;