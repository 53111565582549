import {elementInPosition} from "../util";
import simpleParallax from "simple-parallax-js";

const single_careers = {
  init: () => {
    /* add parallax effect
     * for config options see: https://github.com/geosigno/simpleParallax.js#settings
     */
    const parallax = document.querySelectorAll('.parallax');
    new simpleParallax(parallax);
  },
  finalize: () => {
    const $career_title = $(".career-title");
    const $career_details = $(".career-details");
    const $career_website = $(".career-website");
    const $requirements_list = $(".requirements-list");
    const $cta_text = $(".cta-text");

    $(document).on("ready scroll", () => {
      if (elementInPosition($career_title, 0.5)) {
        const $group_items = $career_title.find(".fade-text");
        $group_items.each((i, elem) => {
          setTimeout(() => {
            $(elem).removeClass("hidden");
          }, i * 200);
        });
      }

      if (elementInPosition($career_website, 0.2)) {
        $career_website.removeClass("hidden");
      }

      if (elementInPosition($career_details, 0.5)) {
        const $group_items = $career_details.find(".group-item");
        $group_items.each((i, elem) => {
          setTimeout(() => {
            $(elem).removeClass("hidden");
          }, i * 200);
        });
      }

      if (elementInPosition($requirements_list, 0.2)) {
        const $group_items = $requirements_list.find(".requirement-item");
        $group_items.each((i, elem) => {
          setTimeout(() => {
            $(elem).removeClass("hidden");
          }, i * 200);
        });
      }

      if (elementInPosition($cta_text, 0.5)) {
        const $group_items = $cta_text.find(".fade-text");
        $group_items.each((i, elem) => {
          setTimeout(() => {
            $(elem).removeClass("hidden");
          }, i * 200);
        });
      }
    });
  },
}
export default single_careers;
