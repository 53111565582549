/*
jQuery(document).ready(function ($) {

  $('#wp-admin-bar-fw-package a').on('click', function(e) {
    e.preventDefault();
    toastr.info('Packaging and uploading theme')
    $.ajax({
      url: '/wp-json/development/package',
      type: 'post',
      headers: {
        "X-WP-Nonce": window.wprest.nonce
      },
      dataType: 'json',
      success: function (data) {
        toastr.success('Theme Packaged')
      },
      fail: function(data) {
        toastr.error('Failed to package theme')
      }
    })
  })

  $('#wp-admin-bar-fw-recompile-scss a').on('click', function (e) {
    // stop button from going somewhere
    e.preventDefault();

    toastr.info('Compiling CSS')

    // call wp-json endpoint to compile scss
    $.ajax({
      url: '/wp-json/development/compile-scss',
      type: 'post',
      headers: {
        "X-WP-Nonce": window.wprest.nonce
      },
      dataType: 'json',
      success: function (data) {
        let queryString = '?reload=' + new Date().getTime();
        $('link[rel="stylesheet"]').each(function () {
          this.href = this.href.replace(/\?.*|$/, queryString);
        });
        toastr.success('Successfully compiled css')
      },
      fail: function(data) {
        toastr.error('Failed to compile css')
      }
    });
  })
})*/
