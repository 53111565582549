import Lottie from "lottie-web";
import {chainAnimate, clamp, isIE} from "./util";

let privateProps;
// const speed = 50;

/**
 * class
 */
export default class inspireDraw {
  constructor() {
    privateProps = new WeakMap();
    const props = {
      now: 0,
      past: 0,
      delta: 0,
      callbacks: {},
      done: false,
    }

    privateProps.set(this, props);

    this.anim = Lottie.loadAnimation({
      container: document.querySelector(".inspire .svg-wrap"),
      renderer: "svg",
      loop: false,
      autoplay: false,
      path: wp.assetPath + "/images/animation-data.json",
    });

    this.anim.addEventListener("data_ready", () => {
      $(".svg-wrap > svg").css("height", "");
      if(!isIE()) {
        $(".svg-wrap > svg").addClass("filled");
      }
      this.animateSVGAuto();
    })
  }

  animateSVG() {
    //let counter = 0;
    // let lastScrollTop = 0;
    let animate;

    animate = () => {

      this.deltaScroll();

      // const frame = clamp($(document).scrollTop() * 0.5, 0, 600);
      const complete = this.anim.currentFrame === this.anim.totalFrames;

      if ($(".svg-wrap svg").offset().top - $(window).scrollTop() < $(window).height() * 0.45 && complete) {
        //if animation is complete & user has scrolled past animation, exit
        this.runCallbacks('complete');

        // Fix orange bar
        // $('.inspirefix').removeClass('sticky');
      }

      // if (frame >= (this.anim.totalFrames * 0.5)) {
      //   $(".svg-wrap > svg").removeClass("filled");
      // } else {
      //   $(".svg-wrap > svg").addClass("filled");
      // }

      //Depending in direction of scroll the animation will draw/undraw itself
      // let st = window.pageYOffset || document.documentElement.scrollTop;
      // let dir;
      //
      // if (st > lastScrollTop) {
      //   dir = 1;
      // } else {
      //   dir = -1;
      // }
      // lastScrollTop = st <= 0 ? 0 : st;

      // if (complete && dir === 1 && !this.done) {
      //   privateProps.get(this).done = true;
      //   this.showExtras().then(() => {
      //     this.runCallbacks('complete');
      //   });
      //
      // } else if (!complete) {
      //   this.hideExtras();
      //   privateProps.get(this).done = false;
      //   $(".inspire-container img").addClass("hide");
      //   this.runCallbacks('incomplete');
      //
      //   // Un-fix orange bar
      //   // $('.inspirefix').addClass('sticky');
      // }

      // this.anim.goToAndStop(frame, true);
    }

    this.animateSVGAuto();

    // if(isIE()) {
    //
    // } else {
    //   //$(document).on("scroll", animate);
    // }
  }

  animateSVGAuto() {
    this.anim.playSpeed = 3;
    this.anim.play()
    this.anim.addEventListener('complete', () => {
      privateProps.get(this).done = true;
      this.showExtras().then(() => {
        this.runCallbacks('complete');
      });
    });
  }

  showExtras() {
    console.log("show extras");
    const finished = new $.Deferred();
    const text = $(".inspire-container .text");

    chainAnimate(() => {
      if (this.done) {
        $(".inspire-container img").removeClass("hide");
      }
    }).then(
      chainAnimate(() => {
        if (this.done) {
          text.removeClass("hide");
        }
      }, 500)).then(
      chainAnimate(() => {
        if (this.done) {
          text.removeClass("folded");
        }
        finished.resolve();
      }, 1000));

    return finished.promise();
  }

  hideExtras() {
    const text = $(".inspire-container .text");
    text.addClass("folded");
    text.addClass("hide");
  }

  get delta() {
    return privateProps.get(this).delta;
  }

  get done() {
    return privateProps.get(this).done;
  }

  deltaScroll() {
    const now = privateProps.get(this).now = $(document).scrollTop();
    const past = privateProps.get(this).past;

    privateProps.get(this).delta = Math.abs(now - past);
    privateProps.get(this).past = now;

    return this.delta;
  }

  /**
   * 'Service' function designed to attach event listeners onto the underlying Lottie object,
   * without exposing the (privately owned) instance within this wrapper class
   *
   * @param eventName See Lottie-Web documentation for list of events
   * @param callback function to be executed on event trigger
   */
  addEventListener(eventName, callback) {
    let eventCallbacks = privateProps.get(this).callbacks[eventName];

    if (!eventCallbacks) {
      eventCallbacks = privateProps.get(this).callbacks[eventName] = [];
    }
    eventCallbacks.push(callback);
    //this.anim.addEventListener(eventName, callback);
  }

  runCallbacks(event) {
    const callbacks = privateProps.get(this).callbacks;
    if (event in callbacks) {
      $(privateProps.get(this).callbacks[event]).each((i, cb) => {
        cb();
      });
    }
  }
}
