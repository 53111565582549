import {chainAnimate} from "./util";

const sliders = {
    init: function () {
        $('#hero_banner').not('slick-initialized').slick({
            autoplay: true,
            autoplaySpeed: 10000,
            // fade: true,
            infinite: true,
            dots: false,
            appendDots: $('.banner-dots'),
            arrows: false,
            appendArrows: $('.banner-arrows'),
            prevArrow: '<i class="fal fa-long-arrow-left fa-lg"></i>',
            nextArrow: '<i class="fal fa-long-arrow-right fa-lg"></i>',
            adaptiveHeight: false,
        });

        $('.projects-slider').not('slick-initialized').slick({
            autoplay: true,
            autoplaySpeed: 10000,
            fade: true,
            infinite: true,
            dots: false,
            arrows: true,
            appendArrows: $('.slider-arrows'),
            prevArrow: '<i class="fal fa-long-arrow-left fa-lg"></i>',
            nextArrow: '<i class="fal fa-long-arrow-right fa-lg"></i>',
            adaptiveHeight: false,
        });

        $('.testimonials').not('slick-initialized').slick({
            fade: true,
            infinite: true,
            dots: true,
            appendDots: $('.testimonial-dots'),
            arrows: true,
            appendArrows: $('.testimonial-controls'),
            prevArrow: '<div class="arrow-prev"></div>',
            nextArrow: '<div class="arrow-next"></div>',
            adaptiveHeight: true,
        });
    

        $('.vision-slider').not('slick-initialized').slick({
            infinite: false,
            dots: true,
            arrows: true,
            appendDots: $('.vision-dots'),
            customPaging: function (slider, i) {
                if ((i + 1) < slider.slideCount) {
                    return '<a> 0' + (i + 1) + '  / </a>';
                } else {
                    return '<a> 0' + (i + 1) + '</a>';
                }
            },
            appendArrows: $('.vision-control'),
            prevArrow: '<div class="arrow vision-left"><i class="fal fa-long-arrow-left fa-2x d-flex align-items-center"></i></div>',
            nextArrow: '<div class="arrow vision-right"><i class="fal fa-long-arrow-right fa-2x d-flex align-items-center"></i></div>',
            adaptiveHeight: false,
            centerMode: true,
            centerPadding: 0,
            slidesToShow: 1.4,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });
    },

    breakdown: () => {
        $('#hero_banner.slick-initialized').slick("unslick");
        $('.projects-slide.slick-initialized').slick("unslick");
        $('.testimonials.slick-initialized').slick("unslick");
    },

    addEvents: () => {
        // Slide Counter
        let status = $('.slider-counter');
        let slickElement = $('.projects-slider');

        slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
            //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
            let active = (currentSlide ? currentSlide : 0) + 1;
            let i;
            let output = '';

            for (i = 1; i <= slick.slideCount; i++) {
                if (active === i) {
                    output += '<span>0' + i;
                } else {
                    output += '<span class="numbers">0' + i;
                }

                output += '</span>';

                if (i < slick.slideCount) {
                    output += '<span class="numbers"> / </span>';
                }
            }

            status.html(output);
        });
        slickElement.on("init reInit", (event, slick, currentSlide, nextSlide) => {
            slickElement.find(".slick-active").addClass("fade-in-out");
        })
        slickElement.on("beforeChange", (event, slick, currentSlide, nextSlide) => {
            setTimeout(() => {
                $(slick.$slides[currentSlide]).removeClass("fade-in-out");
                $(slick.$slides[nextSlide]).addClass("fade-in-out");
            }, 500)
        });


        // Team Slider
        $('.team-left').on('click', function () {
          let container = $(this).closest('.team-slider').find('.member-container');
          let leftPos = container.scrollLeft();
          container.animate({scrollLeft: leftPos - 300}, 300);
        });
        $('.team-right').on('click', function () {
            let container = $(this).closest('.team-slider').find('.member-container');
            let leftPos = container.scrollLeft();
            container.animate({scrollLeft: leftPos + 300}, 300);
        });

        //Vision Slider
        $(".vision-slider").on("init reInit", function(event, slick) {
            $(".vision-slider").prepend('<span class="shape-outline" data-mod="2"></span>');
        });
        $(".vision-slider").on('init reInit afterChange', function (event, slick) {
            const curSlide = $(".vision-slider .slick-current");
            const index = slick.$slides.index(curSlide[0]); // get index of current slide in $slides

            curSlide.animate({opacity: 1}, 200, "swing", () => {
                curSlide.css({visibility: "visible"});
            });

            curSlide.css({
                opacity: 1,
                visibility: "visible",
            });

            if(index <= 0) {
                return;
            } //if current slide is first slide, exit

            const slideOnLeft = $(slick.$slides[index - 1]);
            slideOnLeft.animate({opacity: 0}, 400, "swing", () => {
                slideOnLeft.css({visibility: "hidden"});
            });
        })
    },

    animate: () => {
        const headings = $('#hero_banner.slick-initialized .slick-slide.slick-active .heading');
        const subheadings = $('#hero_banner.slick-initialized .slick-slide.slick-active .subheading');
        const contents = $('#hero_banner.slick-initialized .slick-slide.slick-active .content');
        const buttons = $('#hero_banner.slick-initialized .slick-slide.slick-active .button-container');

        chainAnimate(() => {
            headings.removeClass("hidden");
        }).then(() => {

            chainAnimate(() => {
                subheadings.removeClass("hidden");
            }, 1000).then(() => {

                chainAnimate(() => {
                    contents.removeClass("hidden");
                }, 1000).then(() => {

                    chainAnimate(() => {
                        buttons.removeClass("hidden");
                    }, 1000).then(() => {
                        $('#hero_extra').removeClass("hidden");
                    });
                })
            })
        })
    },
};

export default sliders;
