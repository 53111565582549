/**
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the directory.
 *
 * @type {{
 *     addEvent: SageRouter.addEvent,
 *     loadEvents: SageRouter.loadEvents,
 *     _directory: {},
 *     _fire: SageRouter._fire
 * }}
 */
const SageRouter = {
    _directory: {},
    _fire: function(func, funcname, args) {
        let fire;
        let namespace = SageRouter._directory;
        funcname = (funcname === undefined) ? 'init' : funcname;
        fire = func !== '';
        fire = fire && namespace[func];
        fire = fire && typeof namespace[func][funcname] === 'function';

        if (fire) {
            namespace[func][funcname](args);
        }
    },

    /**
     * Add an 'event' to the router that will only fire if the matching body class(es) are found
     *
     * @param name body class(es) to match against
     * @param init Function callback that will be fired on page load
     * @param finalize Function callback that will be fired after all init functions have been called
     */
    addEvent: function(name, init, finalize) {
        SageRouter._directory[name] = {
            init: init,
            finalize: finalize,
        };
    },

    /**
     * Fire all added and stored events in the directory
     */
    loadEvents: function() {
        // Fire common init JS
        SageRouter._fire('common');

        // Fire page-specific init JS, and then finalize JS
        $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
            SageRouter._fire(classnm);
            SageRouter._fire(classnm, 'finalize');
        });

        // Fire common finalize JS
        SageRouter._fire('common', 'finalize');
    },
};

export default SageRouter;
