import {elementInPosition} from "../util";

const contact = {
  init: () => {

  },
  finalize: () => {
    const $text = $(".module-text");

    $(document).on("ready scroll", () => {
      if (elementInPosition($text, 0.5)) {
        $text.find(".fade-text").removeClass("hidden")
      }
    });
  },
}
export default contact;
