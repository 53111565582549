import Counter from "../counter";
import sliders from "../sliders";
import tileLoad from "../tileload";
import ShapesParallax from "../ShapesParallax";
import {elementInPosition} from "../util";

let endWhatWeDo = false;
let endDiff = false;
let endProject = false;
let endClients = false;

function animateFadeIn() {
  const whatWeDo = $("#what-we-do .inner-content");
  if (!endWhatWeDo && elementInPosition(whatWeDo, 0.5)) {
    whatWeDo.removeClass("hidden");

    let delay = 0;
    whatWeDo.find(".service ul li").each((index, li) => {
      delay += 500;
      setTimeout(() => {
        $(li).removeClass("hidden");
      }, delay);
    })

    endWhatWeDo = true;
  }

  const difference = $(".module-difference .items");
  if (!endDiff && elementInPosition(difference, 0.33)) {
    let delay = 0;
    difference.find(".item").each((index, item) => {
      delay += 500;
      setTimeout(() => {
        $(item).removeClass("hidden");
      }, delay);
    });
    difference.find(".item").each((index, item) => {
      delay += 500;
      setTimeout(() => {
        $(item).css("opacity", 1);
      }, delay);
    });
    endDiff = true;
  }

  const project = $(".project-description");
  if (!endProject && elementInPosition(project, 0.33)) {
    $(project).removeClass("hidden");
    endProject = true;
  }

  const clients = $(".module-clients .inner-content");
  if (!endClients && elementInPosition(clients, 0.20)) {
    $(clients).removeClass("hidden");
    endClients = true;
  }
}

const home = {
  init: () => {
    Counter.init();

    let Sliders = Object.create(sliders);
    Sliders.addEvents();
    Sliders.init();
    Sliders.animate();

    // Page animations
    animateFadeIn();
    new tileLoad().init();
  },
  finalize: () => {
    new ShapesParallax(
      $(".module-difference .relationships .side-image").children(),
      $(".module-difference")
    );

    $(document).scroll(() => {
      animateFadeIn();
    })

    $(document).on('click', 'a[href^="#"]', function (event) {
      event.preventDefault();

      $('a[href^="#"]').removeClass('active');

      const $this = $(this);
      if (!$this.hasClass('active')) {
        $this.addClass('active');
      }

      $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top,
      }, 500);
    });
  },
}
export default home;
