import sliders from "../sliders";
import simpleParallax from "simple-parallax-js";
import {elementInPosition} from "../util";

const singleProjects = {
  init: () => {
    let Sliders = Object.create(sliders);
    Sliders.addEvents();
    Sliders.init();
    Sliders.animate();

    /* add parallax effect
     * for config options see: https://github.com/geosigno/simpleParallax.js#settings
     */
    const parallax = document.querySelectorAll('.parallax');
    new simpleParallax(parallax);
  },
  finalize: () => {
    const $social_links = $(".social-links");
    const $project_title = $(".project-title");
    const $project_details = $(".project-details");
    const $project_website = $(".project-website");
    const $gallery_cta = $(".gallery-cta");
    const $cta = $(".cta");

    $(document).on("ready scroll", () => {
      if (elementInPosition($social_links, 0.5)) {
        const $group_items = $social_links.find(".link-item");
        $group_items.each((i, elem) => {
          setTimeout(() => {
            $(elem).removeClass("hidden");
          }, i * 500);
        });
        $social_links.find(".fade-text").removeClass("hidden");
      }

      if (elementInPosition($project_title, 0.5)) {
        const $group_items = $project_title.find(".fade-text");
        $group_items.each((i, elem) => {
          setTimeout(() => {
            $(elem).removeClass("hidden");
          }, i * 200);
        });
      }

      if (elementInPosition($project_details, 0.5)) {
        const $group_items = $project_details.find(".group-item");
        $group_items.each((i, elem) => {
          setTimeout(() => {
            $(elem).removeClass("hidden");
          }, i * 200);
        });
      }

      if (elementInPosition($project_website, 0.2)) {
        $project_website.removeClass("hidden");
      }

      if (elementInPosition($gallery_cta, 0.2)) {
        $gallery_cta.removeClass("hidden")
      }

      if (elementInPosition($cta, 0.2)) {
        const $group_items = $cta.find(".fade-text");
        $group_items.each((i, elem) => {
          setTimeout(() => {
            $(elem).removeClass("hidden");
          }, i * 200);
        });
      }
    });
  },
}
export default singleProjects;
