window.Popper = require('popper.js').default;
// Load jquery
window.$ = require('jquery');
// Load bootstrap
require('bootstrap');
// reference window.jQuery as window.$
window.jQuery = window.$ = jQuery.noConflict();
// Load slick carousel
require('slick-carousel');
// Load Selectize
require('selectize');
// Load a custom javascript file
require('./admin.js');

import simpleParallax from 'simple-parallax-js';
import lightbox from "lightbox2";
import SageRouter from "./sageRouter";

/**
 * Import specific page JS
 */
import home from "./pages/home";
import development from "./pages/development";
import about from "./pages/about";
import contact from "./pages/contact";
import careers from "./pages/careers";
import archive_projects from "./pages/archive-projects";
import single_projects from "./pages/single-projects";
import single_careers from "./pages/single-careers";

/**
 * Utils
 */
import stickyHeader from "./stickyHeader";
import {elementInPosition} from "./util";

// window._ = require('lodash');
// window.axios = require('axios');
// window.Vue = require('vue');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
//
// window.Vue.component('example-component', require('./components/ExampleComponent.vue'));
//
// const app = new window.Vue({
//   el: '#app',
// });

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Wordpress Json back-end. This library automatically handles sending the
 * Nonce token as a header based on the value of the "nonce" token cookie.
 */

// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
//
// const token = window.wp.nonce;
//
// if (token) {
//   window.axios.defaults.headers.common['X-WP-Nonce'] = token;
// } else {
//   console.error('nonce token not found');
// }


/**
 * Setup different logic for different pages
 */
SageRouter.addEvent(
  "common",
  () => {
    new stickyHeader().init();
    /* add parallax effect
     * for config options see: https://github.com/geosigno/simpleParallax.js#settings
     */
    const parallax = document.querySelectorAll('.parallax');
    new simpleParallax(parallax, {
      scale: 1.2,
    });
  },
  () => {
    // Page menu scroll fixed
    if ($('.page-menu').length) {
      let pageMenu = $('.page-menu').offset().top - 100;

      $(document).on("ready scroll", () => {
        let currentScroll = $(window).scrollTop(); // get current position

        if (currentScroll >= pageMenu) {           // apply position: fixed if you
          $('.page-menu').addClass('menufixed')
        } else {                                   // apply position: static
          $('.page-menu').removeClass('menufixed');
        }
      });
    }

    $(".module-text").each((i, elem) => {
      const $elem = $(elem);

      $(document).on("ready scroll", () => {
        if (elementInPosition($elem, 0.5)) {
          $elem.removeClass("hidden");
        }
      });

    });

    $("a.scrollLink").click(function (event) {
      event.preventDefault();
      $("html, body").animate({scrollTop: $($(this).attr("href")).offset().top}, 1000);
    });

    // Read more slide
    $('.read-more-link').on('click', function () {
      $(this).next($('.read-more-text')).slideToggle();
    });

    $(".orange-block").each((i, elem) => {
      const $elem = $(elem);

      $(document).on("ready scroll", () => {
        if (elementInPosition($elem.parents("section"), 0.15)) {
          $elem.addClass("show");
        }
      });
    });

    lightbox.option({
      'disableScrolling': true,
    });
  });

SageRouter.addEvent("home", home.init, home.finalize);

SageRouter.addEvent("development", development.init, development.finalize);

SageRouter.addEvent("construction", development.init, development.finalize);

SageRouter.addEvent("property_services", development.init, development.finalize);

SageRouter.addEvent("about", about.init, about.finalize);

SageRouter.addEvent("contact", contact.init, contact.finalize);

SageRouter.addEvent("post_type_archive_projects", archive_projects.init, archive_projects.finalize);

SageRouter.addEvent("single_projects", single_projects.init, single_projects.finalize);

SageRouter.addEvent("post_type_archive_careers", careers.init, careers.finalize);

SageRouter.addEvent("single_careers", single_careers.init, single_careers.finalize);

$(document).ready(SageRouter.loadEvents);
