import $ from "jquery/dist/jquery";
import { CountUp } from 'countup.js';
import {elementInPosition} from "./util";

const interval = 500; //milliseconds

export default class Counter {

    static init() {
        const modules = $(".module-counter");
        let i = 0;
        for(const module of modules) {
            i++;
            $(module).attr("id", i);
        }

        $(document).scroll( () => {
            modules.each((i, elem) => {
                if( !$(elem).data("counting") && elementInPosition( $(elem), 0.5) ) {
                    this._animate( $(elem) );
                    $(elem).data("counting", true);
                }
            });
        });
    }

    static _animate(modules) {
        const counters = modules.find(".counter");
        let i = 0;

        for( const counter of counters) {
            i++;
            $(counter).attr("id", $(counter).parents(".module-counter").attr("id") + "-" + i );
            const $data = $(counter).find("span[role='data']");
            const cup = new CountUp( $(counter).find(".number")[0], $data.attr("data-to"), {startVal: $data.attr("data-from")});
            !cup.error ? cup.start() : console.log(cup.error);
        }
    }
}