// Trigger load more
import {getRandomInt} from "./util";

export default class tileLoad {
    // Fade in tiles
    init() {
        this.iter = 0;
        this.$logoGrid = $('.logo-grid');

        $(window).on("scroll.tileload", this.scrollEventCallback.bind(this));

        // Load more tiles
        $('#load-more-link').on('click', function () {
            let counter = 1;
            const $gridNone = $('.logo-grid > .logo-hidden');

            $gridNone.each((i, elem) => {
                counter++;
                if (counter <= 12) {
                    $(elem).removeClass('logo-hidden');
                }
            });
            if ($('.logo-grid > .logo-hidden').length === 0) {
                $('#load-more-link').hide();
            }
        });
    }

    /**
     * Iterate through logo images and randomise the order they appear
     */
    scrollEventCallback() {
        if (this.inPosition()) {
            const logos = this.$logoGrid.find('.logo-item');
            let i;

            while(this.iter < logos.length) {
                i = getRandomInt(logos.length);
                const logo = logos.eq(i).find("img");

                if(logo.length > 0 && !logo.attr("data-done")) {
                    this.iter++;
                    logo.attr("data-done", true);
                    setTimeout(() => {
                        logo.removeClass('hidden');
                    }, this.iter * 200);
                }
            }
            $(window).off("scroll.tileload"); //unregister to stop more scroll event calls
        }
    }

    inPosition() {
        const hT = this.$logoGrid.offset().top,
              hH = this.$logoGrid.outerHeight(), // don't need this because on mobile it waits for scroll to button before initialising
              wH = $(window).height(),
              wS = $(window).scrollTop();
        return wS > (hT - wH);
    }
}

export function randomShow($items, interval, iterator) {
    let i;

    while(iterator < $items.length) {
        i = getRandomInt($items.length);
        const item = $items.eq(i);

        if(item.length > 0 && !item.attr("data-done")) {
            iterator++;
            item.attr("data-done", true);
            setTimeout(() => {
                item.removeClass('hidden');
            }, iterator * interval);
        }
    }
}
