import {isMobileDevice} from "./util";

export default class stickyHeader {

    constructor() { //quick singleton setup
        if(stickyHeader._instance) {
            return stickyHeader._instance;
        }
        stickyHeader._instance = this;
        this.offsetPos = 0;
        this.header = $('header');

        if(this.header.length === 0) {
            console.log("ERROR: header element not found");
        }
    }

    init() {
        // Get the header
        const stickyHeight = this.header.outerHeight();

        $(document).scroll(() => {


            const curPos = $(document).scrollTop();

            const dir = curPos > this.offsetPos ? 1 : -1; // scroll direction, down = positive
            this.offsetPos = curPos;
            if (window.pageYOffset > 0) {
                let className;
                if(!isMobileDevice() ) {
                  className = dir > 0 ? 'down' : 'up';
                }

                this.header.attr('class', 'banner sticky ' + className);
            } else {
                this.header.attr('class', 'banner');
            }
        });
    }
}
