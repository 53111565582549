import {elementInPosition} from "../util";
import sliders from "../sliders";

let endProject = false;

function animateFadeIn() {
  const project = $(".project-description");
  const $text = $(".module-text");
  const $team = $(".module-team");

  if (!endProject && elementInPosition(project, 0.33)) {
    $(project).removeClass("hidden");
    endProject = true;
  }
  if (elementInPosition($text, 0.5)) {
    $text.find(".fade-text").removeClass("hidden")
  }

  if (elementInPosition($team, 0.5)) {
    $team.find(".content-container").removeClass("hidden");

    const $members = $team.find(".member-container .member-info");
    $members.each((i, elem) => {
      setTimeout(() => {
        $(elem).removeClass("hidden");
      }, i * 200);
    });
  }
}

const development = {
  init: ()=> {
    let Sliders = Object.create(sliders);
    Sliders.addEvents();
    Sliders.init();
    Sliders.animate();

    // Page animations
    animateFadeIn();
  },
  finalize: () => {
    $(document).scroll(() => {
      animateFadeIn();
    })
  },
}
export default development;
