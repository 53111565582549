import simpleParallax from "simple-parallax-js";
import {elementInPosition} from "../util";
import {randomShow} from "../tileload";

const archive_projects = {
  init: () => {
    /* add parallax effect
     * for config options see: https://github.com/geosigno/simpleParallax.js#settings
     */
    const parallax = document.querySelectorAll('.parallax');
    new simpleParallax(parallax);
  },
  finalize: () => {
    const $fade_text = $(".fade-text");
    const $projects = $(".projects");
    let i = 0;

    // $(document).on("ready", () => {
    //   $('.selectize').selectize({});
    // });

    $(document).on("ready scroll", () => {
      if (elementInPosition($fade_text, 0.5)) {
        $fade_text.removeClass("hidden");
      }
    });

    $(document).on("ready scroll.project-list", () => {
      if (elementInPosition($projects.find(".project-single"), 0.5)) {
        randomShow($projects.find(".project-single"), 250, i);
        $(document).off("scroll.project-list");
      }
    });
  },
}
export default archive_projects;
