import {elementInPosition} from "../util";
import {randomShow} from "../tileload";
import "masonry-layout/dist/masonry.pkgd.min";
import sliders from "../sliders";
import ShapesParallax from "../ShapesParallax";

const careers = {
  init: () => {
    let Sliders = Object.create(sliders);
    Sliders.addEvents();
    Sliders.init();
    Sliders.animate();

    const $jobs = $('.module-vacancies .joblist > .anim-wrapper');
    $jobs.each((i, elem) => {
      const height = $(elem).find(".job").height();
      $(elem).css({"padding-bottom": height + "px"});
    });

    $("section.module-culture .gallery").masonry({
      itemSelector: ".brick",
      columnWidth: ".column-sizer",
    })

    new ShapesParallax(
      $(".workingatdeluca__container .quote-svg"), $(".workingatdeluca__wrapper"),
      100
    );

  },
  finalize: () => {
    const $text = $(".module-text");
    const $culture = $(".module-culture");
    const $content_container = $culture.find(".content-container");
    const $vacancies = $('.module-vacancies');
    const $jobs = $vacancies.find(".joblist .job-wrapper");
    const $cadets = $(".module-cadetship");
    const $testimonials = $(".module-testimonials");
    const $lifeatdeluca = $(".module-lifeatdeluca");
    const $workingatdeluca = $(".module-workingatdeluca");
    const cadetsImage = $cadets.find(".featured-image");
    let i = 0;

    $(document).on("ready scroll.gallery", () => {
      if (elementInPosition($culture.find(".gallery"), 0.33)) {
        randomShow($culture.find(".gallery .brick img"), 250, i);
        $(document).off("scroll.gallery");
      }
    });

    $(document).on("ready scroll", () => {
      if (elementInPosition($content_container, 0.33)) {
        $content_container.removeClass("hidden")
      }
      if (elementInPosition($text, 0.5)) {
        $text.find(".fade-text").removeClass("hidden")
      }
      if (elementInPosition($vacancies, 0.33)) {
        $vacancies.find(".content-container").removeClass("hidden");
      }
      if (elementInPosition($jobs, 0.33)) {
        $jobs.each((i, elem) => {
          $(elem).removeClass("hidden");
        })
      }
      if (elementInPosition($cadets, 0.33)) {
        $cadets.find(".content-container").removeClass("hidden");
      }
      if (elementInPosition(cadetsImage, 0.33)) {
        cadetsImage.removeClass("hidden");
      }
      if (elementInPosition($cadets.find(".actiontext"), 0.33)) {
        $cadets.find(".actiontext").removeClass("hidden");
      }
      if (elementInPosition($lifeatdeluca, 0.33)) {
        $lifeatdeluca.find(".content-container").removeClass("hidden");
      }
      if (elementInPosition($lifeatdeluca, 0.33)) {
        $lifeatdeluca.find(".image-info").removeClass("hidden");
      }
      if (elementInPosition($testimonials, 0.33)) {
        $testimonials.find(".content-container").removeClass("hidden");
      }
      if (elementInPosition($testimonials, 0.33)) {
        $testimonials.find(".testimonial-container").removeClass("hidden");
      }
      if (elementInPosition($workingatdeluca, 0.33)) {
        $workingatdeluca.find(".content-container").removeClass("hidden");
      }
      if (elementInPosition($workingatdeluca, 0.33)) {
        $workingatdeluca.find(".workingatdeluca__wrapper").removeClass("hidden");
      }
    })
  },
}
export default careers;
