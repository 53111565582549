import {clamp, elementInPosition} from "./util";

let privateProps;

export default class ShapesParallax {

    constructor(elements, sectionElem, offset = 135) {
        this.$elems = $(elements);
        this.$sectionElem = $(sectionElem);

        privateProps = new WeakMap();
        const props = {
            prevScroll: 0,
            offset,
        }
        privateProps.set(this, props);

        this.parallaxDifference();
    }

    get prevScroll() {
        return privateProps.get(this).prevScroll;
    }

    get offset() {
        return privateProps.get(this).offset;
    }

    parallaxDifference() {
        // const sideImage = $(".module-difference .relationships .side-image");
        let curScroll = $(document).scrollTop();
        let dir = curScroll > this.prevScroll ? 1 : -1; // scroll direction, down = positive

        this.$elems.each((i, elem) => {
            $(elem).css({"transform": "translateY(" +  this.offset + "px"});
        });

        $(document).scroll(() => {
            curScroll = $(document).scrollTop();
            dir = curScroll > this.prevScroll ? 1 : -1;
            privateProps.get(this).prevScroll = curScroll;

            if( elementInPosition(this.$sectionElem, 0.1) ) {
                const dist = this.$sectionElem.offset().top - $(document).scrollTop();

                this.$elems.each((i, elem) => {
                    const newVal = Math.max(dist /  $(elem).attr("data-mod"), 0);

                    $(elem).css({"transform": "translateY(" +  newVal + "px)"});
                });
            }
        });
    }

    elemOnScreen($elem) {
        const rect = $elem[0].getBoundingClientRect();
        return rect.top < window.innerHeight && rect.bottom > 0;
    }
}
